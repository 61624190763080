<template>
  <div>
    <preparation-form
      v-for="(form, index) in list"
      :ref="`preparationForm${index}`"
      :key="index"
      :model="list[index]"
      :title="title"
      :withLabel="index === 0"
      @remove="handleRemove(index)"
    ></preparation-form>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import PreparationForm from './PreparationForm'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  components: {
    PreparationForm
  },
  data() {
    return {
      formTemplate: {
        introduction: '',
        resourceList: []
      },
      list: []
    }
  },
  mounted() {
    // const { formTemplate } = this
    // this.list = [cloneDeep(formTemplate)]
  },
  methods: {
    initData(formList) {
      this.list = formList
    },
    add() {
      this.list.push(cloneDeep(this.formTemplate))
    },
    handleRemove(e, index) {
      this.list.splice(index, 1)
    },
    validate() {
      const { $refs, list } = this

      const valids = []
      for (let i = 0; i < list.length; i++) {
        const target = `preparationForm${i}`

        valids.push(
          new Promise((resolve, reject) => {
            $refs[target][0].$refs.form.validate((valid, err) => {
              if (valid) {
                resolve(valid)
                return
              }
              reject(err)
            })
          })
        )
      }

      return valids
    },
    formatForm() {
      const { $refs, list } = this
      const formList = []

      for (let i = 0; i < list.length; i++) {
        const target = `preparationForm${i}`

        const item = $refs[target][0].formatForm()
        formList.push(item)
      }

      return formList
    }
  }
}
</script>

<style lang="less" scoped></style>
