<template>
  <a-form-model ref="form" :model="form" v-bind="!withLabel ? $formItemLayoutWithoutLabel : $formItemLayout">
    <a-form-model-item :label="withLabel ? title : ''">
      <a-row class="form-item-block" :gutter="[0, 4]">
        <a-col>
          <div style="position:relative">
            <a-input
              v-model="form.introduction"
              placeholder="请输入内容"
              :maxLength="200"
              :autoSize="{ minRows: 4, maxRows: 4 }"
              type="textarea"
            />
            <div style="position:absolute;bottom:-5px;right:1px;">
              <span style="background:#fff">{{ form.introduction.length }}/200</span>
            </div>
          </div>
        </a-col>

        <a-col>
          <resource-upload
            :accept="['jpg', 'png', 'jpeg', 'gif', 'mp4']"
            :limit="1024 * 1024 * 200"
            @success="data => form.resourceList.push(data)"
            @error="handleResourcesError"
            @change="handleResourcesChange"
          ></resource-upload>

          <div v-if="form.resourceList.length > 0" style="margin-top:8px">
            <a-row
              v-for="(item, index) in form.resourceList"
              :key="index"
              style="margin-bottom:8px;line-height:normal"
              type="flex"
              align="middle"
            >
              <a-col flex="1" style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;">
                <a
                  v-if="item.type === 'VIDEO'"
                  target="blank_"
                  style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
                  @click="handlePreviewVideo(item.url)"
                >
                  <a-icon type="file" style="margin-right:8px" />{{ item.name }}
                </a>
                <a
                  v-else
                  :href="item.url"
                  target="blank_"
                  style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
                >
                  <a-icon type="file" style="margin-right:8px" />{{ item.name }}
                </a>
              </a-col>
              <a-col class="error-color" style="margin-left:16px;cursor:pointer" @click="removeResource(index)">
                <a-icon type="delete" class="error-color" />
              </a-col>
            </a-row>
          </div>

          <div class="disabled-color" style="line-height:1.5">
            提示：jpg/png/jpeg/gif/MP4格式文件，大小不超过200MB
          </div>
        </a-col>

        <a-icon class="dynamic-delete-button" type="minus-circle-o" @click="$emit('remove')" />
      </a-row>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { cloneDeep } from 'lodash'
import { ResourceUpload } from '@/components/_heath'
import { previewVideo } from '@/utils/util'

export default {
  components: {
    ResourceUpload
  },
  props: {
    model: {
      type: Object,
      default() {
        return {
          introduction: '',
          resourceList: []
        }
      }
    },
    withLabel: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.model,
      resourceListErr: ''
    }
  },
  watch: {
    model: {
      handler(newValue) {
        if (newValue) {
          newValue.resourceList = newValue.resourceList || []
        }
        this.form = newValue
      },
      immediate: true
    }
  },
  methods: {
    formatForm() {
      const ret = cloneDeep(this.form)

      return { ...ret }
    },
    removeResource(index) {
      this.form.resourceList.splice(index, 1)
    },
    handleResourcesError(err) {
      this.resourceListErr = err
    },
    handleResourcesChange() {
      this.resourceListErr = ''
    },
    //
    handlePreviewVideo(url) {
      previewVideo(url)
    }
  }
}
</script>

<style lang="less" scoped>
.dynamic-delete-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(200%, -50%);
  &:hover {
    color: #f5222d;
  }
}
.form-item-block {
  padding: 16px;
  background: @comp-bg-color;
}
</style>
